<template>
  <router-view />
</template>

<script lang="ts" setup></script>

<style lang="scss">
@import './assets/styles/variables';
@import './assets/styles/ui/select';
@import './assets/styles/ui/input';
@import './assets/styles/ui/buttons';
@import './assets/styles/ui/tables';
@import './assets/styles/ui/datepicker';
@import './assets/styles/ui/editor';
@import './assets/styles/ui/progress_bar';
@import './assets/styles/ui/contributors_list';
@import './assets/styles/ui/modal';
@import './assets/styles/ui/pagination';
@import './assets/styles/ui/live_monitor';
@import './assets/styles/ui/card';

body {
  height: auto;

  #app {
    min-height: 100%;
  }

  .va-data-table__vuetable th.sortable {
    color: #000000;
  }
  .va-sidebar__item {
    cursor: pointer;
  }
  .display-2 {
    font-size: 2rem;
    font-weight: 700;
  }
}
</style>
