import axios, { AxiosInstance } from 'axios';

const options = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
};

const apiClient: AxiosInstance = axios.create(options);

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  if (config.headers) config.headers['Content-Type'] = 'application/json';
  return config;
});

export default apiClient;
