import { createApp } from 'vue';
import { createPinia } from 'pinia';
import 'floating-vue/dist/style.css';
import {
  createVuesticEssential,
  VaButton,
  VaCard,
  VaProgressBar,
  VaInnerLoading,
  VaInput,
  VaModal,
  VaSidebarItem,
  VaSidebarItemContent,
  VaSidebarItemTitle,
  VaSidebar,
  VaIcon,
  VaCollapse,
  VaAccordion,
  VaConfig,
  VaSelect,
  VaDateInput,
  VaDataTable,
  VaCardTitle,
  VaPagination,
  VaTabs,
  VaTab,
  VaCheckbox,
  VaSwitch,
} from 'vuestic-ui';
import FloatingVue from 'floating-vue';
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import http from './plugins/axios';
import { AxiosKey } from './plugins/axios/symbols';

import 'vuestic-ui/dist/styles/essential.css';
import 'vuestic-ui/dist/styles/grid.css';
import 'vuestic-ui/dist/styles/reset.css';
import 'vuestic-ui/dist/styles/typography.css';
import './assets/styles/main.scss';
import './assets/styles/variables.scss';
import 'ant-design-vue/dist/antd.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

const app = createApp(App);
const pinia = createPinia();

axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

app.use(pinia);
app.use(router);
app.use(
  createVuesticEssential({
    components: {
      VaButton,
      VaCard,
      VaCardTitle,
      VaProgressBar,
      VaInnerLoading,
      VaInput,
      VaModal,
      VaSidebarItemContent,
      VaSidebarItemTitle,
      VaSidebarItem,
      VaSidebar,
      VaIcon,
      VaCollapse,
      VaAccordion,
      VaConfig,
      VaSelect,
      VaDateInput,
      VaDataTable,
      VaPagination,
      VaTabs,
      VaTab,
      VaCheckbox,
      VaSwitch,
    },
    config: {
      components: {
        // // all: {
        // //   color: '#FFFFFF',
        // // },
        // VaButton: {
        //   color: '#000000',
        // },
      },
      colors: {
        variables: {
          'background-element': '#FFFFFF',
          primary: '#000000',
        },
      },
    },
  })
);

app.use(FloatingVue);

app.component('TreeTable', TreeTable).component('tree-column', Column);

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

app.provide(AxiosKey, http);

app.mount('#app');
