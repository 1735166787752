import { defineStore } from 'pinia';
import { State, LoginData } from '@/store/auth/types';
import axios from 'axios';
import router from '@/router';

export const useAuthStore = defineStore('auth', {
  state(): State {
    return {
      isAdmin: false,
      profile: null,
      isFetchingProfile: false,
    };
  },
  actions: {
    async getUserProfile() {
      return new Promise((resolve, reject) => {
        this.isFetchingProfile = true;
        axios
          .get('/dashboard/profile')
          .then((res) => {
            this.profile = res.data.data;
            if (res.data.data.roles.includes('ROLE_ADMIN')) {
              this.isAdmin = true;
            } else {
              this.isAdmin = false;
            }
            this.isFetchingProfile = false;
            resolve(res.data.data);
          })
          .catch((e) => {
            console.error(e);
            this.isFetchingProfile = false;
            reject(e);
          });
      });
    },
    async login(data: LoginData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/getToken', data)
          .then((res) => {
            localStorage.setItem('accessToken', res.data.token);
            axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
            resolve(true);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    logout() {
      localStorage.removeItem('accessToken');
      this.isAdmin = false;
      axios.defaults.headers.common.Authorization = undefined;
      this.profile = null;
      router.push({ name: 'Login' });
    },
  },
});
